import React from 'react';
import { HandHoldingPhoneImage, UnionIcon } from '../../assets';

const Header = () => {
  return (
    <header className="text-center relative overflow-hidden px-6">
    <div className="mt-60 mb-8">
      <h1 className="text-5xl lg:text-7xl max-w-[800px] mx-auto mb-[22px] tracking-tighter">
        <span className="bg-clip-text gradient-text bg-gradient-to-r from-[#F6C1C9] via-[#A1CEEB] to-[#0B1956]">
          Streamlining service{" "}
        </span>
        procedures for therapists
      </h1>
      <p className="text-xl text-[#667085] max-w-[480px] mx-auto">
        Welcome to Meind, where we empower therapists by simplifying their
        service processes.
      </p>
    </div>

    <div className="flex gap-3 md:gap-5 justify-center max-w-[542px] mx-auto">
      <input
        className="rounded-lg border-2 border-[#E0DFDF] grow h-[50px] px-6"
        placeholder="Enter your email"
      />
      <button className="border-2 shrink-0 border-[#0B1956] px-5 rounded-lg notif">
        Notify me
      </button>
    </div>
    <img
      className="block w-[80%] max-w-[660px] ml-[15%] md:ml-[25%] xl:ml-[35%] mt-[74px]"
      src={HandHoldingPhoneImage}
      alt="hand holding phone"
      loading="lazy"
    />
    <img
      className="absolute bottom-0 w-full z-[-1] left-0"
      src={UnionIcon}
      alt="Meind"
      loading="lazy"
    />
  </header>
  )
}

export default Header